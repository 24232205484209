interface FeatureFlagProps {
  [flagName: string]: boolean; // Dynamic flag names with boolean values
}

/**
 * Feature flags are used to enable or disable certain features in the application.
 * They can be toggled via query parameters or local storage.
 * Example: `[path]?OEMTemplatesEnabled=true`
 */

const featureFlags: FeatureFlagProps = {
  oemTemplates: getFlagState('FF_OEMTemplatesEnabled', 'oemTemplates'),
};

function getFlagState(flagName: string, localStorageKey: string) {
  // Check query parameter first (for overrides)
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has(flagName)) {
    return urlParams.get(flagName) === 'true';
  }

  // Check local storage fallback
  const localStorageValue = localStorage.getItem(localStorageKey);
  return localStorageValue === null ? false : JSON.parse(localStorageValue);
}

export default featureFlags;
