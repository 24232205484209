import { defineMessages } from 'react-intl';

const messages = defineMessages({
  accessoriesLabel: {
    id: 'accessories.accessoriesLabel',
    defaultMessage: 'Accessories **',
  },
  accessoriesPlaceholder: {
    id: 'accessories.accessoriesPlaceholder',
    defaultMessage: 'Type Accessory',
  },
  priceLabel: {
    id: 'accessories.priceLabel',
    defaultMessage: 'Price',
  },
  msrpLabel: {
    id: 'accessories.msrpLabel',
    defaultMessage: 'MSRP',
  },
  buttonLabel: {
    id: 'accessories.buttonLabel',
    defaultMessage: 'Add',
  },
  financeableLabel: {
    id: 'accessories.financeableLabel',
    defaultMessage: 'Financeable',
  },
  boatsFinanceableDisclaimer: {
    id: 'accessories.boatsFinanceableDisclaimer',
    defaultMessage:
      'Only bolt-on type products such as stereo and navigation systems can be financed. Items that are not bolt-on type products cannot be included in the financed amount.',
  },
  motorcyclesFinanceableDisclaimer: {
    id: 'accessories.motorcyclesinanceableDisclaimer',
    defaultMessage:
      'Only bolt-on type products such as  suspension and luggage racks can be financed. Items that are not bolt-on type products cannot be included in the financed amount.',
  },
  golfCartFinanceableDisclaimer: {
    id: 'accessories.golfCartFinanceableDisclaimer',
    defaultMessage:
      'Only bolt-on type products such as wheels and infotainment can be financed. Items that are not bolt-on type products cannot be included in the financed amount.',
  },
  noItems: {
    id: 'accessories.noItems',
    defaultMessage: 'No accessories',
  },
});

export default messages;
