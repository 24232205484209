import { ChangeEvent, useEffect, useState } from 'react';
import './Input.scss';
import { Field } from 'formik';
import tooltipIcon from '../../assets/info_bubble.png';
import eye from '../../assets/eye.png';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

type InputProps = {
  type?: 'text' | 'number' | 'password' | 'email' | 'tel' | 'currency' | 'date' | 'textarea';
  label?: string;
  placeholder: string;
  value: string | number | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  disabled?: boolean;
  error: string | undefined;
  disclaimer?: string;
  required?: boolean;
  pattern?: string;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  currency?: boolean;
  className?: string;
  tooltip?: string;
  onClick?: (e: ChangeEvent<HTMLInputElement>) => void;
  readonly?: boolean;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const formatPhoneNumber = (input: string) => {
  if (input.startsWith('+1')) {
    input = input.substring(2);
  }

  // const cleaned = ('' + input).replace(/\D/g, ''); // Only digits
  const cleaned = ('' + input).replace(/[^\d.]/g, ''); // Only digitsand decimal point

  if (cleaned.length === 0) {
    return undefined;
  } else if (cleaned.length <= 3) {
    return `(${cleaned})`;
  } else if (cleaned.length <= 6) {
    return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3)}`;
  } else {
    return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6)}`;
  }
};

export const formatCurrency = (input: string) => {
  const cleaned = ('' + input).replace(/[^\d.]/g, ''); // Only digits and decimal point
  const parts = cleaned.split('.');
  const dollars = parts[0];
  const cents = parts.length > 1 ? '.' + parts[1] : '';
  const dollarsWithCommas = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return cents ? `${dollarsWithCommas}${cents}` : dollarsWithCommas;
};

const Input = ({
  type = 'text',
  label,
  placeholder,
  value,
  onChange,
  onClick,
  name,
  disabled = false,
  error,
  disclaimer,
  required = true,
  pattern,
  currency = false,
  className,
  tooltip,
  readonly = false,
  onFocus,
}: InputProps) => {
  const [toggleState, setToggleState] = useState(type);

  const handleTypeToggle = () => {
    if (toggleState === 'password') {
      setToggleState('text');
    } else {
      setToggleState('password');
    }
  };

  const [formattedValue, setFormattedValue] = useState<string | number | undefined>(value);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (type === 'tel') {
      const formatted = formatPhoneNumber(e.target.value);
      setFormattedValue(formatted);
      // This will call the external onChange handler with unformatted value
      if (formatted) {
        e.target.value = formatted.replace(/\D/g, '');
      }
      onChange(e);
    } else if (type === 'currency') {
      const rawValue = String(e.target.value).replace(/[^\d.-]/g, ''); // Keep digits, period, and minus sign
      const formatted = formatCurrency(rawValue);
      setFormattedValue(formatted);
      // Pass the raw value (with digits and decimal point) to the external onChange handler
      e.target.value = rawValue;
      onChange(e);
    } else {
      setFormattedValue(e.target.value);
      onChange(e);
    }
  };

  useEffect(() => {
    handleInputChange({
      target: {
        value: value,
      },
    } as ChangeEvent<HTMLInputElement>);
  }, [value]);

  return (
    <div className={`text-input-container ${className || ''}`} data-testid={`input-label-${name}`}>
      <label className="text-input-label" htmlFor={name} aria-labelledby={name} title={`${name} label`}>
        {label}
        {!required && <span className="optional">(optional)</span>}
        {!!tooltip && (
          <>
            <img
              data-tooltip-id={`${name}-tooltip`}
              className="tooltip"
              src={tooltipIcon}
              alt="tooltip"
              data-tooltip-content={tooltip}
            />
            <Tooltip id={`${name}-tooltip`} place="top" />
          </>
        )}
      </label>
      <div className="input-wrapper">
        {currency && <span className="currency-prefix">$</span>}
        <Field
          readOnly={readonly}
          data-testid={`input-field-${name}`}
          className={`text-input ${currency ? 'currency' : ''}`}
          name={name}
          id={name}
          title={`${name} input`}
          type={toggleState}
          placeholder={placeholder}
          value={type === 'tel' || type === 'currency' ? formattedValue : value}
          onChange={handleInputChange}
          aria-label={`${label}--input`}
          disabled={disabled || readonly}
          pattern={pattern}
          onClick={onClick}
          onFocus={onFocus}
        />
        {type === 'password' ? (
          <>
            <img
              className="password-reveal"
              src={eye}
              alt="tooltip"
              data-testid="password-toggle"
              onClick={handleTypeToggle}
            />
          </>
        ) : null}
      </div>
      <div className="error-messaging" data-testid="error-messaging">
        {!!error && error}
      </div>
      {disclaimer ? <p className="disclaimer">{disclaimer}</p> : null}
    </div>
  );
};

export default Input;
