import { Outlet } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import './Screen.scss';
import { NavLinkProp } from '../../types.ts';
// @ts-expect-error TS2307: Cannot find module probably because it's out of src directory.
import version from '../../../../VERSION';

interface ScreenProps {
  navigationLinks?: NavLinkProp[];
}

const Screen = ({ navigationLinks }: ScreenProps) => {
  const portal = () => {
    const port = window.location.port;

    switch (port) {
      case '5100':
        return 'Consumer Portal';
      case '5200':
        return 'Admin Portal';
      case '5300':
        return 'Dealer Portal';
    }
  };
  return (
    <>
      <div className="screen-mode">
        {import.meta.env.MODE === 'development' && (
          <>
            <span>Development Mode</span>
            <span>{portal()}</span>
          </>
        )}
        {import.meta.env.MODE !== 'PRODUCTION' && import.meta.env.MODE !== 'test' && (
          <span>{`Version ${version}`}</span>
        )}
      </div>
      <Header navigationLinks={navigationLinks} />
      <div className="Screen">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Screen;
