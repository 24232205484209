import BackButton from '@luna-protocol/core/src/components/BackButton/BackButton';
import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup';
import Calendar from '@luna-protocol/core/src/components/Calendar/Calendar';
import Card from '@luna-protocol/core/src/components/Card/Card';
import CustomerName from '@luna-protocol/core/src/components/CustomerName/CustomerName';
import Input from '@luna-protocol/core/src/components/Input/Input.tsx';
import { USDollar } from '@luna-protocol/core/src/utils/constants/format.ts';
import { useAuth } from '@luna-protocol/core/src/utils/useAuth.ts';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { AppContext } from '../../AppContext.tsx';
import { useGetSerialNumbers } from '../../queries/useGetSerialNumbers.ts';
import { usePostFinalise } from '../../queries/usePostFinalise.ts';
import messages from './ReviewDeal.messages.ts';
import { useGetApplicationSummary } from '../../queries/useGetApplicationSummary.ts';
import './ReviewDeal.scss';
import { AdditionalInformation } from './AdditionalInformation.tsx';
import { usePostAdditionalInfo } from '../../queries/usePostAdditionalInfo.ts';
import { useGetDealer } from '../../queries/useGetDealerInfo.ts';
import { useDealerID } from '../../utils/useDealerID.ts';

export type ReviewDealValues = {
  primaryUseOfCollateral: string | undefined;
  options: [string] | undefined;
  unitAddress: string | undefined;
  primaryAddress: string | undefined;
  emailOverride: string;
  primaryStreetAddress: string | undefined;
  primaryCity: string | undefined;
  primaryCounty: string | undefined;
  primaryState: { label: string; value: string } | undefined;
  unitStreetAddress: string | undefined;
  unitCity: string | undefined;
  unitCounty: string | undefined;
  unitState: { label: string; value: string } | undefined;
  asIsSale: string | undefined;
  action: string | undefined;
};

const ReviewDeal = () => {
  useAuth();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { loanApplicationID, floorplanDetails, selectedProgram, deal, customerName, OEMType } = useContext(AppContext);
  const [attestation, setAttestation] = useState<boolean>(false);
  const { data: serialNumberData } = useGetSerialNumbers(loanApplicationID);
  const { finaliseApplication } = usePostFinalise(loanApplicationID as string);
  const { data: applicationSummary } = useGetApplicationSummary(loanApplicationID as string);
  const { mutateAsync: postAdditionalInfo } = usePostAdditionalInfo(loanApplicationID as string);
  const dealerId = useDealerID();
  const { data: dealer, isLoading: isGetDealerLoading } = useGetDealer(dealerId || undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: ReviewDealValues = {
    primaryUseOfCollateral: undefined,
    options: undefined,
    unitAddress: undefined,
    primaryAddress: undefined,
    emailOverride: '',
    primaryStreetAddress: undefined,
    primaryCity: undefined,
    primaryCounty: undefined,
    primaryState: undefined,
    unitStreetAddress: undefined,
    unitCity: undefined,
    unitCounty: undefined,
    unitState: undefined,
    asIsSale: undefined,
    action: undefined,
  };

  const reviewDealSchema = Yup.object().shape(
    {
      primaryUseOfCollateral: Yup.string().when([], (_, schema) => {
        if (applicationSummary?.applicants[0]?.state === 'NV') {
          return schema.required('This field is required');
        } else {
          return schema.notRequired();
        }
      }),
      options: Yup.array(),
      unitAddress: Yup.string().when([], (_, schema) => {
        if (applicationSummary?.applicants[0]?.state === 'NV') {
          return schema.required('This field is required');
        } else {
          return schema.notRequired();
        }
      }),
      unitStreetAddress: Yup.string().when('unitAddress', {
        is: 'no',
        then: schema => schema.required('This field is required'),
      }),
      unitCity: Yup.string().when('unitAddress', {
        is: 'no',
        then: schema => schema.required('This field is required'),
      }),
      unitCounty: Yup.string().when('unitAddress', {
        is: 'no',
        then: schema => schema.required('This field is required'),
      }),
      unitState: Yup.object().when('unitAddress', {
        is: 'no',
        then: schema => schema.required('This field is required'),
      }),
      primaryAddress: Yup.string().when([], (_, schema) => {
        if (applicationSummary?.applicants[0]?.state === 'NV') {
          return schema.required('This field is required');
        } else {
          return schema.notRequired();
        }
      }),
      primaryStreetAddress: Yup.string().when('primaryAddress', {
        is: 'no',
        then: schema => schema.required('This field is required'),
      }),
      primaryCity: Yup.string().when('primaryAddress', {
        is: 'no',
        then: schema => schema.required('This field is required'),
      }),
      primaryCounty: Yup.string().when('primaryAddress', {
        is: 'no',
        then: schema => schema.required('This field is required'),
      }),
      primaryState: Yup.object().when('primaryAddress', {
        is: 'no',
        then: schema => schema.required('This field is required'),
      }),
      emailOverride: Yup.string().when('emailOverride', (val, schema) => {
        if (val?.length > 0) {
          return schema.email(formatMessage(messages.invalidEmail));
        } else {
          return Yup.string().notRequired();
        }
      }),
      asIsSale: Yup.string().when([], (_, schema) => {
        if (dealer?.address?.state === 'WV') {
          return schema.required('This field is required');
        } else {
          return schema.notRequired();
        }
      }),
    },
    [['emailOverride', 'emailOverride']],
  );

  const [selectedDate, setDate] = useState<Date>(new Date());

  const financiables = deal?.accessories?.filter(acc => acc.is_financeable);
  const financiableTotals = financiables?.reduce((acc, curr) => acc + Number(curr.price), 0);
  const totalRequested = () => {
    if (deal?.totals) {
      return (
        (deal?.totals.base_product_total || 0) +
        (financiableTotals || 0) +
        (deal?.totals.additions || 0) +
        (deal?.totals.backend_products || 0) -
        (deal?.totals.deductions || 0)
      );
    }
    return 0;
  };

  const handleAttestation = () => {
    setAttestation(!attestation);
  };

  const getParticipation = () => {
    return (totalRequested() * (Number(selectedProgram?.participation) || 0)) / 100;
  };

  const getSerialNumberLabel = (serial_number_type: string) => {
    if (OEMType === 'brunswick') {
      switch (serial_number_type) {
        case 'hull-id':
          return formatMessage(messages.confirmedHINLabel);
        case 'engine':
          return formatMessage(messages.confirmedEngineLabel);
        case 'trailer':
          return formatMessage(messages.confirmedTrailerLabel);
      }
    }
    if (OEMType === 'ezgo') {
      return formatMessage(messages.confirmedSerialNumber);
    }
    return formatMessage(messages.confirmedVINLabel);
  };

  const totalFloorplanBalance = useMemo(() => {
    let sum = 0;
    floorplanDetails?.forEach(e => {
      if (e !== undefined) {
        sum += e.floorplan_balance;
      }
    });
    return sum;
  }, [floorplanDetails]);

  const totalOutstanding = useMemo(() => {
    if (deal === undefined) {
      return 0;
    }
    return (
      (deal?.totals?.base_product_total || 0) +
      (deal?.totals?.additions || 0) +
      (deal?.totals?.backend_products || 0) +
      (financiableTotals || 0)
    );
  }, [deal, financiableTotals]);

  function getInterest() {
    if (selectedProgram === undefined) {
      return '';
    }

    // if there is a promo show the promo rate (i.e. 7.99%/9.99%)
    // but only if the promo rate is different than the standard rate to avoid showing 9.99%/9.99%
    if (selectedProgram.program_type_code === 'LLBUYDOWN') {
      return `${(selectedProgram.promo_coupon * 100).toFixed(2)}%`;
    }
    if (selectedProgram.promo_coupon && selectedProgram.promo_coupon !== selectedProgram.coupon) {
      return `${(selectedProgram.promo_coupon * 100).toFixed(2)}%/${(selectedProgram.coupon * 100).toFixed(2)}%`;
    }
    // if there is no promo show the standard rate (i.e. 9.99%)
    return `${(selectedProgram.coupon * 100).toFixed(2)}%`;
  }

  if (isGetDealerLoading) {
    return null;
  }

  return (
    <>
      <BackButton path="/add_products" inverted />
      <Banner alternate>{formatMessage(messages.title)}</Banner>
      <CustomerName applicantName={customerName || ''} />
      <Body fullWidth stretched>
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            setIsSubmitting(true);
            if (!loanApplicationID) {
              console.warn('No loan application ID');
              return;
            }

            const today = new Date();
            const todayDateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            const selectedDateOnly = new Date(
              selectedDate.getFullYear(),
              selectedDate.getMonth(),
              selectedDate.getDate(),
            );

            const params: {
              delivery_date: string | undefined;
              dealer_email_override: string | null;
              as_is_sale?: boolean;
            } = {
              delivery_date: undefined,
              dealer_email_override: values.emailOverride,
              ...(values.asIsSale !== undefined && {
                as_is_sale: values.asIsSale === 'yes' ? true : false,
              }),
            };

            // Check if selectedDate is today by comparing the date parts only
            if (selectedDateOnly.getTime() === todayDateOnly.getTime()) {
              // If selectedDate is today, set the first parameter to undefined
              params.delivery_date = undefined;
            } else {
              // Otherwise, prepare the delivery_date parameter with formattedDate
              const formattedDate = selectedDate.toISOString().split('T')[0];
              params.delivery_date = formattedDate;
            }

            if (values.primaryUseOfCollateral !== undefined) {
              try {
                await postAdditionalInfo({
                  primary_use: values.primaryUseOfCollateral,
                  options_included: values.options || [],
                  stored_at_customer: values.unitAddress === 'yes' ? true : false,
                  ...(values.unitAddress === 'no' && {
                    stored_address: {
                      address_lines: [values.unitStreetAddress || ''],
                      city: values.unitCity || '',
                      state: values.unitState?.value || '',
                      zip_code: values.unitCounty || '',
                    },
                  }),
                  customer_primary_address_valid: values.primaryAddress === 'yes' ? true : false,
                  ...(values.primaryAddress === 'no' && {
                    customer_primary_address: {
                      address_lines: [values.primaryStreetAddress || ''],
                      city: values.primaryCity || '',
                      state: values.primaryState?.value || '',
                      zip_code: values.primaryCounty || '',
                    },
                  }),
                });
              } catch (e) {
                console.error('error', e);
                return;
              }
            }
            // exit early before finalisation
            if (values.action === 'save') {
              navigate('/prequalification');
              return;
            }

            finaliseApplication(params, {
              onSuccess: () => {
                navigate('/complete');
              },
              onError: context => {
                console.error('error', context);
              },
            });
          }}
          validationSchema={reviewDealSchema}
          enableReinitialize
          validateOnMount>
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <h1 className="review-deal--title">{formatMessage(messages.subtitle)}</h1>
                <div className="review-deal--container">
                  <div className="inner-container left">
                    <h2>{formatMessage(messages.itemTitle)}</h2>
                    <Card>
                      <table className={'property-table'}>
                        <tbody>
                          <tr>
                            <td className={'label'}>{formatMessage(messages.itemLabel)}</td>
                            <td className={'value'}>{serialNumberData?.item_name}</td>
                          </tr>
                          {serialNumberData?.serial_numbers.map(serialNumber => (
                            <>
                              <tr>
                                <td className={'label'}>{formatMessage(messages.modelLabel)}</td>
                                <td className={'value'}>{serialNumber?.make}</td>
                              </tr>
                              <tr>
                                <td className={'label'}>{formatMessage(messages.modelLabel)}</td>
                                <td className={'value'}>{serialNumber?.model}</td>
                              </tr>
                              <tr>
                                <td className={'label'}>{formatMessage(messages.nada_msrp_invoice)}</td>
                                <td className={'value'}>{serialNumber?.nada_msrp_invoice}</td>
                              </tr>
                              <tr key={serialNumber.serial_number}>
                                <td className={'label'}>{getSerialNumberLabel(serialNumber.serial_number_type)}</td>
                                <td className={'value'}>{serialNumber.serial_number}</td>
                              </tr>
                              <tr>
                                <td className={'label'}>{formatMessage(messages.conditionLabel)}</td>
                                <td className={'value'}>{serialNumber.condition}</td>
                              </tr>
                              {serialNumber.condition === 'new' ? (
                                <tr>
                                  <td className={'label'}>{formatMessage(messages.invoiceValueLabel)}</td>
                                  <td className={'value'}>{USDollar.format(serialNumber.seller_purchase_price)}</td>
                                </tr>
                              ) : (
                                <tr>
                                  <td className={'label'}>{formatMessage(messages.nadaValueLabel)}</td>
                                  <td className={'value'}>{USDollar.format(serialNumber.seller_purchase_price)}</td>
                                </tr>
                              )}
                            </>
                          ))}
                          <tr>
                            <td className={'label'}>{formatMessage(messages.netFloorplanBalanceLabel)}</td>
                            <td className={'value'}>{USDollar.format(totalFloorplanBalance)}</td>
                          </tr>
                          <tr>
                            <td className={'label bold'}>{formatMessage(messages.totalLabel)}</td>
                            <td className={'value bold'}>{USDollar.format(totalRequested())}</td>
                          </tr>
                        </tbody>
                      </table>
                      <NavLink to={'/amend_details'} className={'edit-link'}>
                        {formatMessage(messages.editLabel)}
                      </NavLink>
                    </Card>
                    <h2>{formatMessage(messages.financeTitle)}</h2>
                    <Card>
                      <table className={'property-table'}>
                        <tbody>
                          <tr>
                            <td className={'label'}>{formatMessage(messages.customerNameLabel)}</td>
                            <td className={'value'}>{serialNumberData?.primary_customer_name}</td>
                          </tr>
                          <tr>
                            <td className={'label'}>{formatMessage(messages.totalAmountFinancedLabel)}</td>
                            <td className={'value'}>{USDollar.format(totalRequested())}</td>
                          </tr>
                          <tr>
                            <td className={'label'}>{formatMessage(messages.monthlyRepaymentLabel)}</td>
                            <td className={'value'}>
                              {USDollar.format(Number(selectedProgram?.monthly_payment) || 0)}
                            </td>
                          </tr>
                          <tr>
                            <td className={'label'}>{formatMessage(messages.repaymentPeriodLabel)}</td>
                            <td className={'value'}>
                              {selectedProgram?.tenor} {formatMessage(messages.monthsSuffix)}
                            </td>
                          </tr>
                          <tr>
                            <td className={'label'}>{formatMessage(messages.aprLabel)}</td>
                            <td className={'value'}>{getInterest()}</td>
                          </tr>
                        </tbody>
                      </table>
                      <NavLink to={'/add_products'} className={'edit-link'}>
                        {formatMessage(messages.editLabel)}
                      </NavLink>
                    </Card>
                    {dealer?.address?.state === 'WV' && (
                      <div className="asIsSale">
                        <span>{formatMessage(messages.asIsSale)}</span>
                        <span>
                          <span>
                            <input type="radio" name="asIsSale" value="yes" onChange={handleChange} />
                            <label htmlFor="yes">{formatMessage(messages.yes)}</label>
                          </span>
                          <span>
                            <input type="radio" name="asIsSale" value="no" onChange={handleChange} />
                            <label htmlFor="no">{formatMessage(messages.no)}</label>
                          </span>
                        </span>
                        {errors?.asIsSale && (
                          <div className="error-messaging" data-testid="error-messaging">
                            {errors?.asIsSale}
                          </div>
                        )}
                      </div>
                    )}
                    {applicationSummary?.applicants[0]?.state === 'NV' && (
                      <AdditionalInformation
                        handleChange={handleChange}
                        errors={errors}
                        values={values}
                        applicant={applicationSummary?.applicants[0]}
                      />
                    )}
                    <h2>{formatMessage(messages.deliveryTitle)}</h2>
                    <Card>
                      <div className="delivery-date">
                        <p>{formatMessage(messages.dateSelectInstruction)}</p>
                        <Calendar
                          setDate={date => {
                            setDate(date);
                          }}
                          selectedDate={selectedDate}
                        />
                        <div className="delivery-date--selected">
                          <p>
                            <b>{formatMessage(messages.deliveryDateLabel)}</b>
                          </p>
                          <p>{format(selectedDate, 'MMMM do yyyy')}</p>
                        </div>
                      </div>
                    </Card>
                    <Card>
                      <h2>{formatMessage(messages.emailOverride)}</h2>
                      <Input
                        label={formatMessage(messages.emailLabel)}
                        required={false}
                        placeholder={formatMessage(messages.emailLabel)}
                        name="emailOverride"
                        type="email"
                        value={values.emailOverride}
                        onChange={e => {
                          handleChange(e);
                        }}
                        error={errors.emailOverride ? errors.emailOverride : undefined}
                      />
                    </Card>
                  </div>

                  {/* Container split */}

                  <div className="inner-container right">
                    <h2>{formatMessage(messages.sidebarTitle)}</h2>
                    <Card>
                      <table className={'property-table'}>
                        <tbody>
                          <tr>
                            <td className={'label light'}>{formatMessage(messages.basePurchasePriceLabel)}</td>
                            <td className={'value bold'}>{USDollar.format(deal?.totals?.base_product_total || 0)}</td>
                          </tr>
                          <tr>
                            <td className={'label light'}>{formatMessage(messages.feesAndTaxesLabel)}</td>
                            <td className={'value bold'}>{USDollar.format(deal?.totals?.additions || 0)}</td>
                          </tr>
                          <tr>
                            <td className={'label light'}>{formatMessage(messages.backendProductsLabel)}</td>
                            <td className={'value bold'}>{USDollar.format(deal?.totals?.backend_products || 0)}</td>
                          </tr>
                          <tr>
                            <td className={'label light'}>{formatMessage(messages.financableAccessoriesLabel)}</td>
                            <td className={'value bold'}>{USDollar.format(financiableTotals || 0)}</td>
                          </tr>
                          <tr className={'divider'}>
                            <td className={'label light'}>{formatMessage(messages.downPaymentLabel)}</td>
                            <td className={'value bold'}>
                              -{USDollar.format(deal?.deductions?.find(e => e.key === 'Down payment')?.value || 0)}
                            </td>
                          </tr>
                          <tr className={'divider'}>
                            <td className={'label bold'}>{formatMessage(messages.totalLabel)}</td>
                            <td className={'value bold'}>{USDollar.format(totalRequested())}</td>
                          </tr>
                          <tr>
                            <td className={'label light'}>{formatMessage(messages.rebateLabel)}</td>
                            <td className={'value bold'}>
                              -{USDollar.format(deal?.deductions?.find(e => e.key === 'Rebate')?.value || 0)}
                            </td>
                          </tr>

                          <tr>
                            <td className={'label light'}>
                              {OEMType !== 'ezgo'
                                ? formatMessage(messages.dealerFeeLabel)
                                : formatMessage(messages.participationFeeLabel)}
                            </td>
                            <td className={'value bold'}>{USDollar.format(getParticipation())}</td>
                          </tr>
                          <tr className={'divider'}>
                            <td className={'label light'}>{formatMessage(messages.netFloorplanBalanceLabel)}</td>
                            <td className={'value bold'}>{USDollar.format(totalFloorplanBalance)}</td>
                          </tr>
                          <tr>
                            <td className={'label light'}>{formatMessage(messages.netCashLabel)}</td>
                            <td className={'value bold'}>
                              {USDollar.format(totalOutstanding + getParticipation() - totalFloorplanBalance)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                  </div>
                </div>
                <div className={'review-deal--attestation'}>
                  <p className="center-aligned">{formatMessage(messages.documentSendMessage)}</p>
                  <div className="attestation-checkbox--container">
                    <input
                      className="attestation-checkbox--input"
                      type="checkbox"
                      name="attestation"
                      checked={attestation}
                      onChange={handleAttestation}
                    />
                    <label htmlFor="attestation">{formatMessage(messages.attestationCheckbox)}</label>
                  </div>
                  <div className="attestation-button--container">
                    <ButtonGroup align="center" narrowWidth>
                      <Button testId={'save'} fullWidth={false} onClick={() => setFieldValue('action', 'save')}>
                        {formatMessage(messages.saveButton)}
                      </Button>
                      <Button
                        type={'submit'}
                        testId={'complete'}
                        variant={'secondary'}
                        fullWidth={false}
                        onClick={() => setFieldValue('action', 'complete')}
                        disabled={isSubmitting || !attestation || Object.keys(errors).length > 0}>
                        {formatMessage(messages.completeButton)}
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Body>
    </>
  );
};

export default ReviewDeal;
