import { useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import aquaLogo from '../../assets/aqua-logo.svg';
import { useGetDealerBranding } from '../../queries/useGetDealerBranding.ts';
import { useGetFlowTemplateById } from '../../queries/useGetFlowTemplateById.ts';
import { NavLinkProp } from '../../types.ts';
import isLoggedIn from '../../utils/isLoggedIn.ts';
import { returnImgSrc } from '../../utils/returnImgSrc.ts';
import NavigationLink from '../NavigationLink/NavigationLink';
import './Header.scss';

interface HeaderProps {
  navigationLinks?: NavLinkProp[];
}

const hideLinks = sessionStorage.getItem('is_standalone_dealer') === 'false';

const flowTemplateId = '5d1c08fc-3134-41ce-902f-83c578bc6c2f';

const Header = ({ navigationLinks }: HeaderProps) => {
  const [showLinks, setShowLinks] = useState(false);
  const { data } = useGetDealerBranding(localStorage.getItem('dealer_id') || '');
  const { data: templateData } = useGetFlowTemplateById(flowTemplateId);

  useEffect(() => {
    localStorage.setItem('flow_template', JSON.stringify(templateData));
  }, [templateData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useInterval(() => {
    setShowLinks(isLoggedIn() && !(sessionStorage.getItem('is_standalone_dealer') === 'false'));
  }, 500);

  const returnLogo = () => {
    if (data?.dealer_logo_base64 || templateData?.primary_logo) {
      return (
        <img
          src={`${returnImgSrc((templateData?.primary_logo || data?.dealer_logo_base64) ?? '')}`}
          aria-label="Dealer logo"
          alt="Dealer logo"
          width="300px"
        />
      );
    }

    return <img src={aquaLogo} aria-label="Aqua logo" alt="Aqua logo" />;
  };

  return (
    <header className="header-container">
      {returnLogo()}
      <nav>
        {showLinks &&
          !hideLinks &&
          navigationLinks?.map((link, index) => {
            return (
              <NavigationLink
                label={link.label}
                path={link.url}
                key={index}
                className={index === 0 ? '' : 'border-left'}
              />
            );
          })}
      </nav>
    </header>
  );
};

export default Header;
