import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ReactNode, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useGetDealerBranding } from '../../queries/useGetDealerBranding.ts';

import ButtonGroup from '../ButtonGroup/ButtonGroup';
import messages from './MiniDataTable.messages';
import './MiniDataTable.scss';
import LineBreak from '../LineBreak/LineBreak';
import { Spinner } from '../Spinner/Spinner';
import brunswickLogo from '../../assets/brunswickLogo.svg';
import KTMLogo from '../../assets/KTM.svg';
import EZGOLogo from '../../assets/ezgo-promo.svg';

interface DataTableProps {
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  promotedRanges?: number[];
  readonly?: boolean;
  disabledRanges?: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<unknown, any>[];
  isLoading?: boolean;
  buttons?: ReactNode[];
  updatedAt?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowClick?: (row: any) => void;
  showUpdate?: boolean;
}

type HeaderGroupProps = {
  headers: unknown[];
  depth: number;
  id: string;
};

const MiniDataTable = ({
  data,
  columns,
  isLoading,
  buttons,
  updatedAt,
  onRowClick,
  promotedRanges,
  showUpdate = true,
}: DataTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const { formatMessage } = useIntl();
  const { data: dealerBrandingData } = useGetDealerBranding(localStorage.getItem('dealer_id') || '');
  const [selectedRow, setSelectedRow] = useState();

  const promo_logo = useMemo(() => {
    const template = localStorage.getItem('flow_template');
    if (template) {
      try {
        const parsedTemplate = JSON.parse(template);
        return parsedTemplate?.promotional_logo || '';
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }
    return '';
  }, [localStorage]);
  let logo: string;

  logo = promo_logo
    ? promo_logo
    : (() => {
        switch (dealerBrandingData?.oem_type) {
          case 'ktm':
            logo = KTMLogo;
            break;
          case 'ezgo':
            logo = EZGOLogo;
            break;
          default:
            logo = brunswickLogo;
            break;
        }
      })();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    // uncomment to debug table
    // debugTable: true,
  });

  return (
    <div className="table-screen">
      {showUpdate && <div className="data-update-time">{formatMessage(messages.updated, { date: updatedAt })}</div>}
      {buttons && (
        <div className="table-buttons">
          <ButtonGroup>
            {buttons.map((button, index) => (
              <div className="table-button" key={index}>
                {button}
              </div>
            ))}
          </ButtonGroup>
        </div>
      )}
      <table className="mini-table">
        <thead className="table-head">
          {table.getHeaderGroups().map((headerGroup: HeaderGroupProps) => (
            <tr className="table-row" key={headerGroup.id}>
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {headerGroup.headers.map((header: any) => (
                <th key={header.id} colSpan={header.colSpan} className="header-cell">
                  <div
                    {...{
                      className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                      onClick: header.column.getToggleSortingHandler(),
                    }}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="table-body">
          {isLoading && (
            <tr>
              <td colSpan={columns.length} className="no-data">
                <LineBreak largeMargin />
                <Spinner color={'black'} />
              </td>
            </tr>
          )}
          {!isLoading && !data ? (
            <tr>
              <td colSpan={columns.length} className="no-data">
                {formatMessage(messages.noData)}
              </td>
            </tr>
          ) : (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            table.getRowModel().rows.map((row: any) => (
              <>
                {promotedRanges && row.index in promotedRanges && (
                  <div className={'row-banner'}>
                    <img src={logo} alt={'OEM'} width="150px" />
                    <span>Promoted Financing</span>
                  </div>
                )}
                <tr
                  key={row.id}
                  className={`table-row ${selectedRow === row.original ? 'selected' : ''} ${
                    promotedRanges && row.index in promotedRanges ? 'promoted' : ''
                  }`}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    onRowClick?.(row.original);
                    setSelectedRow(row.original);
                  }}>
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {row.getVisibleCells().map((cell: any) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              </>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MiniDataTable;
