import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'products.title',
    defaultMessage: 'Build Deal',
  },
  addAccessoriesTitle: {
    id: 'products.addAccessoriesTitle',
    defaultMessage: 'Add accessories',
  },
  accessorySummaryTitle: {
    id: 'products.accessorySummaryTitle',
    defaultMessage: 'Accessory summary',
  },
  saveButton: {
    id: 'products.saveButton',
    defaultMessage: 'Save for later',
  },
  viewButton: {
    id: 'products.viewButton',
    defaultMessage: 'View financing offers',
  },
  vinTitle: {
    id: 'products.vinTitle',
    defaultMessage: 'VIN Lookup',
  },
  vinDescription: {
    id: 'products.vinDescription',
    defaultMessage:
      'Select the VIN or serial number of the following units. By confirming this number the dealership is declaring that this is the correct VIN or serial for this transaction. ',
  },
  multipleGAPProducts: {
    id: 'products.multipleGAPProducts',
    defaultMessage: 'Please only select one GAP product.',
  },
  frontEndLTVexceed: {
    id: 'products.frontEndLTVexceed',
    defaultMessage: 'Front End LTV cannot exceed {frontEndLTVLimit}%.',
  },
  backEndLTVexceed: {
    id: 'products.backEndLTVexceed',
    defaultMessage: 'Back End LTV cannot exceed {backEndLTVLimit}%.',
  },
  backEndValueExceed: {
    id: 'products.backEndValueExceed',
    defaultMessage: 'Back End value cannot exceed {backEndValueLimit}%.',
  },
});

export default messages;
