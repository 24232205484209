import { Children, ReactNode, useMemo } from 'react';
import golfBall from '../../assets/golf-ball.svg'; // EZGO
import marine from '../../assets/marine.svg'; // Brunswick
import powersports from '../../assets/powersports.svg'; // KTM
import { OEMType as EOEMType } from '../../types';
import { Hero } from '../Hero/Hero';
import './Banner.scss';
import featureFlags from '../../utils/featureFlags';
import { returnImgSrc } from '../../utils/returnImgSrc';

interface BannerProps {
  children?: ReactNode;
  alternate?: boolean;
  OEMType?: EOEMType;
}

const Banner = ({ children, alternate = false, OEMType = EOEMType.Brunswick }: BannerProps) => {
  const bannerOEMType = useMemo(() => {
    const sessionOEM = sessionStorage.getItem('OEMType');

    if (sessionOEM) {
      return JSON.parse(sessionOEM) as EOEMType;
    } else {
      return OEMType;
    }
  }, [OEMType]);

  const stockImage = useMemo(() => {
    const template = localStorage.getItem('flow_template');
    if (template) {
      // Check if template exists (not null or undefined)
      try {
        const parsedTemplate = JSON.parse(template);
        return parsedTemplate?.stock_image || ''; // Use optional chaining and default to ''
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }
    return '';
  }, [localStorage]);

  // this will need to be some sort of call to get the theme colour
  const currentThemePrimary = '#f56400';

  // primary: 245, 100, 0
  // jaffa: 247, 139, 64
  // hot-pink: 250, 177, 128

  const convertColour = (hex: string, red: number, green: number, blue: number) => {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    r = Math.min(r + red, 255);
    g = Math.min(g + green, 255);
    b = Math.min(b + blue, 255);

    return `rgb(${r}, ${g}, ${b})`;
  };

  const jaffa = convertColour(currentThemePrimary, 2, 39, 64);
  const hotPink = convertColour(currentThemePrimary, 5, 77, 128);

  return (
    <div className="banner-container" role="banner">
      {!alternate && (
        <>
          <Hero>
            {/* remove whole block when removing feature flag */}
            {!featureFlags.oemTemplates &&
              (bannerOEMType === EOEMType.KTM ? (
                <img src={powersports} alt="KTM stock image" aria-label="KTM stock image" />
              ) : bannerOEMType === EOEMType.EZGO ? (
                <img src={golfBall} alt="EzGo stock image" aria-label="EzGo stock image" />
              ) : (
                <img src={marine} alt="Brunswick stock image" aria-label="Brunswick stock image" />
              ))}

            {/* FEATURE FLAG */}
            {featureFlags.oemTemplates &&
              (bannerOEMType === EOEMType.EZGO ? (
                <img src={golfBall} alt="EzGo stock image" aria-label="EzGo stock image" />
              ) : stockImage ? (
                <img src={returnImgSrc(stockImage)} alt="Stock image" aria-label="Stock image" />
              ) : (
                <img src={marine} alt="Brunswick stock image" aria-label="Brunswick stock image" />
              ))}
          </Hero>
          <div className="hot-pink" style={{ backgroundColor: hotPink }}></div>
          <div className="jaffa" style={{ backgroundColor: jaffa }}></div>
        </>
      )}
      <div
        className={`banner-wrapper ${alternate ? 'alternate' : ''}`}
        style={{ backgroundColor: currentThemePrimary }}>
        {children && Children.toArray(children).length > 0 && (
          <div className={alternate ? 'alternate' : ''}>{children}</div>
        )}
      </div>
    </div>
  );
};

export default Banner;
