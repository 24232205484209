import { useContext, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';

import binIcon from '@luna-protocol/core/src/assets/binIcon.png';
import Input from '@luna-protocol/core/src/components/Input/Input';
import { OEMType as OEMTypeEnum } from '@luna-protocol/core/src/types.ts';

import { AppContext } from '../../AppContext';
import messages from './Accessories.message';

import './Accessories.scss';

const AccessoriesList = () => {
  const { formatMessage } = useIntl();
  const { deal, setDeal, OEMType, dealerFlowTemplate } = useContext(AppContext);

  const featureFlag = '?FF_OEMTemplatesEnabled=true';

  let disclaimer;
  switch (OEMType) {
    case 'ktm':
      disclaimer = messages.motorcyclesFinanceableDisclaimer;
      break;
    case 'ezgo':
      disclaimer = messages.golfCartFinanceableDisclaimer;
      break;
    default:
      disclaimer = messages.boatsFinanceableDisclaimer;
      break;
  }
  const onChange = (index: number, key: string, value: string | number | boolean) => {
    if (deal?.accessories === undefined) return;
    const targetedAccessory = deal?.accessories[index];

    const updatedDeal = {
      ...deal,
      accessories: [
        ...deal.accessories.map(accessory => {
          if (accessory === targetedAccessory) {
            if (key === 'is_financeable') {
              return {
                ...accessory,
                is_financeable: !accessory.is_financeable,
              };
            }

            return {
              ...accessory,
              [key]: value,
            };
          }

          return accessory;
        }),
      ],
    };
    setDeal(updatedDeal);
  };

  const handleRemoveAccessory = (index: number) => {
    if (deal?.accessories === undefined) return;

    const targetedAccessory = deal?.accessories[index];

    const updatedDeal = {
      ...deal,
      accessories: [...deal.accessories.filter(accessory => accessory !== targetedAccessory)],
    };
    setDeal(updatedDeal);
  };

  return (
    <Formik initialValues={deal?.accessories || {}} onSubmit={() => {}} validationSchema={{}}>
      {() => (
        <Form>
          <table className="accessories-list">
            {deal?.accessories && deal.accessories?.length > 0 ? (
              <>
                <thead>
                  <tr>
                    <th>{formatMessage(messages.accessoriesLabel)}</th>
                    <th>
                      {featureFlag && dealerFlowTemplate?.accessory_disclaimer_text
                        ? dealerFlowTemplate.accessory_disclaimer_text
                        : OEMType === OEMTypeEnum.KTM
                          ? formatMessage(messages.motorcyclesFinanceableDisclaimer)
                          : OEMType === OEMTypeEnum.EZGO
                            ? formatMessage(messages.golfCartFinanceableDisclaimer)
                            : formatMessage(messages.boatsFinanceableDisclaimer)}
                    </th>
                    <th className="checkbox-title">{formatMessage(messages.financeableLabel)}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {deal.accessories?.map((accessory, index) => {
                    return (
                      <tr key={index} className="accessories-list--row">
                        <td className="text-input-container accessories-input--name">
                          <input
                            className="text-input"
                            type="text"
                            name={accessory.name}
                            value={accessory.name}
                            onChange={e => onChange(index, 'name', e.target.value)}
                            data-testid={`accessory-input--name-${index}`}
                          />
                        </td>
                        <td className="accessories-input--price">
                          <div className="text-input-container">
                            <span className="currency-prefix">$</span>
                            <Input
                              type="currency"
                              name="accessory-price"
                              value={accessory.price}
                              currency
                              onClick={(e: ChangeEvent<HTMLInputElement>) => e.target.select()}
                              onChange={e => onChange(index, 'price', Number(e.target.value))}
                              placeholder="0"
                              error={undefined}
                            />
                          </div>
                        </td>
                        <td className="accessories-checkbox--container">
                          <input
                            className="accessories-checkbox--input"
                            type="checkbox"
                            name="is_financeable"
                            checked={accessory.is_financeable}
                            onChange={e => onChange(index, 'is_financeable', e.target.value)}
                            data-testid={`is_financeable-checkbox-${index}`}
                          />
                        </td>
                        <td className="accessories-delete-row">
                          <button
                            className="delete-row--btn"
                            type="button"
                            onClick={() => handleRemoveAccessory(index)}
                            data-testid={`delete-row-${index}`}>
                            <img className="delete-row--icon" src={binIcon} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={100} className="accessories-disclaimer">
                      <sub>
                        <sup>**</sup>
                        {featureFlag && dealerFlowTemplate?.accessory_disclaimer_text
                          ? dealerFlowTemplate.accessory_disclaimer_text
                          : formatMessage(disclaimer)}
                      </sub>
                    </td>
                  </tr>
                </tbody>
              </>
            ) : (
              <tbody>
                <tr>
                  <td>
                    <span className="no-items">{formatMessage(messages.noItems)}</span>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </Form>
      )}
    </Formik>
  );
};

export default AccessoriesList;
